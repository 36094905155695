import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { checkIsSelectedCategory } from 'ui/component/override-product-listing-page/util/check-url';
import styles from './style.css';

export const MenuHeader = ({
    toggle,
    open,
    category,
    hasChildren,
    level,
    className,
}) => {
    const isSelected = checkIsSelectedCategory(category.url);
    const buttonClasses = classes(styles.header, {
        [styles.nochildren]: !hasChildren,
        [styles.open]: open && hasChildren,
        [styles.isSelected]: isSelected,
    });
    const linkClasses = classes(styles.name, {
        [styles['level-2']]: level === 2,
    });

    return (
        <div className={className}>
            <AccessibleButton
                tag="span"
                action={toggle}
                className={buttonClasses}
            >
                <a
                    className={linkClasses}
                    href={category.url}
                    data-test="cat-link"
                    onClick={(event) => {
                        event.preventDefault();
                        toggle();
                    }}
                >
                    {category.name}
                </a>
                <span className={styles.arrow} />
            </AccessibleButton>
        </div>
    );
};
