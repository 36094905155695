import React, { useState } from 'react';
import { checkIsSelectedCategory } from 'ui/component/override-product-listing-page/util/check-url';
import { MenuHeader } from './menu-header';
import styles from './style.css';

export const MenuItem = ({ item, level = 1 }) => {
    const selectedCategory = window.location.pathname;
    const isItemSelected = checkIsSelectedCategory(item.url);
    const isChildSelected = item.children && item.children.some(child => selectedCategory === child.url);
    const [isMenuExpanded, setMenuExpanded] = useState(isItemSelected || isChildSelected);
    const hasChildren = item.children && item.children.length > 0;
    const className = `${styles.menuItem} ${isItemSelected ? styles.selected : ''}`;
    return (
        <li>
            <MenuHeader
                category={item}
                open={isMenuExpanded}
                toggle={() => setMenuExpanded(!isMenuExpanded)}
                hasChildren={hasChildren}
                level={level}
                className={className}
            />
            {isMenuExpanded && hasChildren && (
            <ul>
                {item.children.map(child => (
                    <MenuItem
                        key={child.id}
                        item={child}
                        level={level + 1}
                    />
                ))}
            </ul>
            )}
        </li>
    );
};
