import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import attributeStyles from 'ui/component/product-listing-page/components/filters/attributes/style.css';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { checkIsDefault } from 'ui/component/override-product-listing-page/util/check-url';
import { FilterAttribute } from '../filter-attribute';
import styles from './style.css';
import { OverrideMenu } from '../../override-menu';

export const Attributes = ({ attributes }) => {
    const [isExpanded, setIsExpanded] = useState(checkIsDefault());

    const t = usePhraseTranslater();
    const { isVerticalMenu } = useOverrideCategoriesInfo();
    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };
    const handleKeyDown = (event) => {
        if (isVerticalMenu && (event.keyCode === 13 || event.keyCode === 32)) {
            toggleExpanded();
        }
    };
    const tabIndex = 0;
    const filterByLabel = t('FILTER BY');
    const expandLabel = isExpanded ? t('EXPAND CATEGORIES...') : t('COLLAPSE CATEGORIES');
    return (
        <div className={styles.box}>
            <div
                role="button"
                className={classes({
                    [styles.filterLabel]: true,
                    [styles.clickableLabel]: isVerticalMenu,
                })}
                onClick={toggleExpanded}
                onKeyDown={handleKeyDown}
                aria-expanded={isExpanded}
                tabIndex={tabIndex}
            >
                {isVerticalMenu ? expandLabel : filterByLabel}
            </div>
            {isVerticalMenu && !isExpanded && (
                <div className={styles.overridemenu}>
                    <OverrideMenu />
                </div>
            )}
            <div className={attributeStyles.attributes}>
                {attributes.map(attribute => (
                    <FilterAttribute
                        key={attribute.id}
                        attribute={attribute}
                    />
                ))}
            </div>
        </div>
    );
};
