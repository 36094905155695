import React from 'react';
import { Wishlist } from 'ui/component/product-listing-page/components/wishlist';
import styles from 'ui/component/product-listing-page/sections/desktop-left-content/styles.css';
import { Filters } from '../../components/filters';
import { Compare } from '../../../product-listing-page/components/compare';

export const DesktopLeftContent = ({ pageState, urls, actions }) => (
    <div className={styles.desktopLeft}>
        <Filters
            pageState={pageState}
            urls={urls}
            actions={actions}
        />
        <Wishlist />
        <Compare />
    </div>
);
