import React from 'react';
import { useScrollOnNavigation } from 'hooks/use-scroll-on-navigation';
import scrollTarget from 'ui/styles/scroll-target.css';
import { MainContent } from 'ui/component/product-listing-page/sections/main-content';
import { MobileTopContent } from 'ui/component/product-listing-page/sections/mobile-top-content';
import styles from 'ui/component/product-listing-page/styles.css';
import { DesktopLeftContent } from './sections/desktop-left-content';

export const OverrideProductListingPage = ({
    pageState,
    urls,
    actions,
    shouldDisplaySort,
    shouldShowRecommendations,
    emptyContent,
}) => {
    const scrollRef = useScrollOnNavigation();

    return (
        <div
            className={scrollTarget.hackOverride}
            ref={scrollRef as React.MutableRefObject<HTMLDivElement>}
        >
            <div className={styles.content}>
                <DesktopLeftContent
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                />
                <MobileTopContent
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                />
                <MainContent
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                    shouldDisplaySort={shouldDisplaySort}
                    shouldShowRecommendations={shouldShowRecommendations}
                    emptyContent={emptyContent}
                />
            </div>
        </div>
    );
};
