import React from 'react';
import { routerStateKey } from '@silkpwa/module/react-component/router-state-key';
import { connectOverrideCategoryMenu } from '@silkpwa/module/react-component/connect-override-category-menu';
import { MenuItem } from './menu-item';

const OverrideMenu = ({ menu }) => (
    <ul>
        {menu.map(category => (
            <MenuItem
                key={category.id}
                item={category}
            />
            ))}
    </ul>
);

const ConnectedMenu = routerStateKey(connectOverrideCategoryMenu(OverrideMenu));

export { ConnectedMenu as OverrideMenu };
