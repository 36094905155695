import React from 'react';
import { Selections } from 'ui/component/product-listing-page/components/filters/selections';
import styles from 'ui/component/product-listing-page/components/filters/content/style.css';
import { Attributes } from '../attributes';

export const Content = ({ selections, attributes, clearAll }) => (
    <div
        style={{
            display: (
                selections.length === 0 &&
                attributes.length === 0
            ) ? 'none' : 'block',
        }}
        className={styles.filterList}
    >
        {selections.length > 0 && (
            <Selections clearAll={clearAll} />
        )}
        {attributes.length > 0 && (
            <Attributes attributes={attributes} />
        )}
    </div>
);
